pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
    text-align: left;
    border-radius: 20px;
    margin: 0 50px 0 50px;
    word-wrap: normal;
  }

#dl-logo {
    height: 15vh;
    margin: auto;
}

.Download {
    display: flex;
    flex-direction: column;
}

.dl-page {
  text-align: center;
  
}