.main {
    background-color: #fff;
    height: 75vh;
    /* min-width subject to change */
    min-width: 600px;
    width: 75vw;
    border-radius: 20px;
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.11);
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.11);
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.11);

    display: flex;
    justify-content: center;
    flex-direction: column;
}

h1, h2, h3, h4, h5, p {
    color: #4E555F;
}

